<template>
  <div>
      <b-row>

        <b-col
          cols="12"
          md="4"
        >
          <h5>Salutation</h5>
            <p>{{ (userData.salutation) ? userData.salutation + ". " : "-" }}</p>
        </b-col>


        <!-- Field: First Name -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>First Name</h5>
            <p>{{ (userData.name) ? userData.name : "-"}}</p>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <h5>Last Name</h5>
            <p>{{ (userData.last_name) ? userData.last_name : "-" }}</p>
        </b-col>
        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Private Email</h5>
            <p>{{ userData.email }}</p>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Status</h5>
            <p>
                <b-badge :variant="statusVariant(userData.email_verified_at)">
                    {{ (userData.email_verified_at !== null) ? 'Verified' : 'Unverified'  }}
                </b-badge>
            </p>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <h5>Role</h5>
            <p>
                <b-badge :variant="roleVariant(userData.roles)" v-if="userData.roles !== undefined && userData.roles.length > 0">
                    {{ userData.roles[0].name }}
                </b-badge>
            </p>
        </b-col>
      </b-row>

  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BBadge
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BBadge,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    statusVariant(emailVerifiedAt) {
      return emailVerifiedAt !== null ? 'light-success' : 'light-danger'
    },
    roleVariant(roles) {
      if(roles === undefined || roles === null) {
        return 'light-info'
      }

      if(roles.length > 0) {
        if(roles[0].name == "Super Admin") {
          return 'light-primary'
        }
        else 
        if(roles[0].name == "Admin") {
          return 'light-primary'
        }
        else
        if(roles[0].name == "User") {
          return 'light-warning'
        }
        else {
            return 'light-info'
        }
      }
      else {
        return 'light-info'
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
