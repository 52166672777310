<template>
  <b-card title="Event History">
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTermEvent"
            placeholder="Search"
            name="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <div v-if="!rows" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <vue-good-table
      v-if="rows"
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTermEvent }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Row number -->
        <span
          v-if="props.column.field === 'row_number'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
        </span>

        <!-- Column: Event Name -->
        <span
          v-if="props.column.field === 'event_name'"
          class="text-nowrap"
        >
          <a @click="viewEvent(props.row.event.id)" href="#">{{ (props.row.event) ? props.row.event.name : "-" }}</a>
        </span>

        <!-- Column: Event Date Start -->
        <span
          v-if="props.column.field === 'event_date'"
          class="text-nowrap"
        >
          {{ (props.row.event.date_start && props.row.event.date_end) ? formatDate(props.row.event.date_start) + "-" + formatDate(props.row.event.date_end) : '-' }}
        </span>

        <!-- Column: Updated at -->
        <span
          v-if="props.column.field === 'created_at'"
          class="text-nowrap"
        >
        </span>

        <!-- Column: Checked in at -->
        <span
          v-if="props.column.field === 'checked_in_at_formatted'"
          class="text-nowrap"
        > {{ (props.row.checked_in_at) ? formatDateReverse(props.row.checked_in_at) : '-' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="detailModal(props.row)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>Detail</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeModal(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span else-if>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>


  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ currentObject.user_name }} from {{ currentObject.event_name }}</h5>
      </template>

      <p>
        Are you sure you want to delete this item?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-detail"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDetailModal"
    >
      <template #modal-header="{}">
        <h5>Profile</h5>
      </template>

      <b-row>
        <b-col cols="12">
          <div v-if="currentUserObject === null" class="text-center mt-2">
            <div class="demo-spacing-0">
              <b-alert
                variant="warning"
                show
              >
                <div class="alert-body">
                  This user hasn't supplied any details
                </div>
              </b-alert>
            </div>
          </div>
        </b-col>
      </b-row>
      
      <b-row v-if="currentUserObject !== null" >
        <b-col cols="6">
          <h5>First Name</h5>
          <p>{{ currentUserObject.user.name }}</p>

          <h5>Last Name</h5>
          <p>{{ (currentUserObject.user.last_name) ? currentUserObject.user.last_name : '-' }}</p>

          <h5>Salutation</h5>
          <p>{{ currentUserObject.user.salutation }}</p>

          <h5>Email</h5>
          <p>{{ (currentUserObject.user.email) ? currentUserObject.user.email : '-' }}</p>

          <h5>Phone</h5>
          <p>{{ (currentUserObject.profile.phone) ? currentUserObject.profile.phone : '-' }}</p>

          <h5>Home Address</h5>
          <p>{{ (currentUserObject.profile.home_address) ? currentUserObject.profile.home_address : '-' }}</p>

          <h5>Office Phone</h5>
          <p>{{ (currentUserObject.profile.company_phone) ? currentUserObject.profile.company_phone : '-' }}</p>

          <h5>Office Email</h5>
          <p>{{ (currentUserObject.profile.company_email) ? currentUserObject.profile.company_email : '-' }}</p>
        </b-col>

        <b-col cols="6">
          <h5>Company Name</h5>
          <p>{{ (currentUserObject.profile.company) ? currentUserObject.profile.company : '-' }}</p>

          <h5>Company Address</h5>
          <p>{{ (currentUserObject.profile.company_address) ? currentUserObject.profile.company_address : '-' }}</p>

          <h5>Company City</h5>
          <p>{{ (currentUserObject.profile.company_city) ? currentUserObject.profile.company_city.name + ", " + currentUserObject.profile.company_city.state.name + ", " + currentUserObject.profile.company_city.state.country.name : '-' }}</p>

          <h5>Brand Name</h5>
          <p>{{ (currentUserObject.profile.brand_name) ? currentUserObject.profile.brand_name : '-' }}</p>

          <h5>Company Size</h5>
          <p>{{ (currentUserObject.profile.company_size) ? currentUserObject.profile.company_size : '-' }}</p>

          <h5>Company Industry</h5>
          <p>{{ (currentUserObject.profile.industry) ?currentUserObject.profile.industry : '-' }}</p>

          <h5>Job Title</h5>
          <p>{{ (currentUserObject.profile.position) ? currentUserObject.profile.position : '-' }}</p>

          <h5>Job Level</h5>
          <p>{{ (currentUserObject.profile.job_level) ? currentUserObject.profile.job_level : '-' }}</p>
        </b-col>

        <b-col cols="12" v-if="currentUserObject.additional_registration_data.length > 0">
          <h4 class="mb-2 mt-1">Additional fields</h4>
          <div v-for="(currentData, index) in currentUserObject.additional_registration_data" :key="index">
            <h5>{{ currentData.field_name }}</h5>
            <p>{{ (currentData.value) ? currentData.value : '-' }}</p>            
          </div>
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <div style="float: left">
          <b-button size="md" variant="outline-secondary" @click="cancel()">
            Back
          </b-button>
        </div>
      </template>


    </b-modal>

  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BCard, BForm, BButton, BSpinner, BModal, BDropdown, BDropdownItem, BRow, BCol
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    BModal,
    BDropdown, 
    BDropdownItem,
    BCol,
    BRow,
    ToastificationContent
  },
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentUserObject: null,
      showDetailModal: false,
      showDeleteModal: false,
      isLoadingDelete: false,
      pageLength: 5,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Event Name',
          field: 'event_name',
        },
        {
          label: 'Event Date',
          field: 'event_date',
        },
        {
          label: 'Registered At',
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd-MM-yyyy HH:mm',
        },
        {
          label: 'Checked In At',
          field: 'checked_in_at_formatted',
        },
        {
            label: 'Actions',
            field: 'actions'
        },
        {
          label: 'event.name',
          hidden: true,
          field: 'event.name'
        },
        {
          label: 'event.date_start',
          field: 'event.date_start',
          hidden: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
        {
          label: 'event.date_end',
          field: 'event.date_end',
          hidden: true,
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd',
          dateOutputFormat: 'dd-MM-yyyy',
        },
      ],
      searchTermEvent: '',
      currentObject: {
        user_name: null,
        event_name: null,
        user_id: -1,
        event_id: -1
      },
    }
  },
  methods: {
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD HH:mm:s').format('DD-MM-YYYY HH:mm')
    },
    detailModal(data) {
      this.showDetailModal = true

      this.currentUserObject = data

    },
    removeModal(data) {
      this.showDeleteModal = true

      this.currentObject = {
        user_id: data.user.id,
        user_name: data.user.name,
        event_id: data.event.id,
        event_name: data.event.name
      }

    },
    remove() {
      const user_id = this.currentObject.user_id
      const event_id = this.currentObject.event_id

      this.isLoadingDelete = true

      this.$http.post('/admin/v1/event_registers/remove', {
        user_id: user_id,
        event_id: event_id
      })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()

      })
    },
    fetchData() {
      const id = this.currentObject.user_id

      const requestData = {
        user_id: id
      }

      this.$http.post('/admin/v1/events_by_user', requestData)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.rows = []

            currentData.forEach((item, i) => {
              this.rows.push(item)
            })
          }
      })
    },
    formatDate(value) {
      return moment(String(value)).format('DD-MM-YYYY')
    },
    viewEvent(id) {
      this.$router.push('/events/' + id + '/view')
    }
  }
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>